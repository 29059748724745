const paths = [
    {
        path: '/attributes-dynamic-link',
        name: 'AttributesDynamicLink',
        title: 'ATTRIBUTES_DYNAMIC_LINK',
        menu: 'asset',
        categories: [
            'configurations',
          ],
        icon: 'mdi-link',
        component: () => import(/* webpackChunkName: "attributesDynamicLink" */ /* webpackMode: "lazy" */ '@/pages/attributesDynamicLink/AttributesDynamicLink.vue'),
    },
];

export default paths;
