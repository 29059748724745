import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import moment from 'moment';
import constsEntries from '@/pages/entries/services/consts';
import DonePendingAll from '@/util/inputs/static/provider/DonePendingAll.json';
const { $autocomplete, $select, $util, $moment, } = Vue.prototype;
export class SaveMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAtendimentoPS',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                maintenanceRelatedId: new Field({
                    name: 'ID_ATENDIMENTO_PS_A_RELACIONAR',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                locationId: new Field({
                    name: 'ID_LOCALIZACAO',
                }),
                localNumber: new Field({
                    name: 'NUMERO_LOCAL',
                }),
                serviceOrderId: new Field({
                    name: 'ID_ORDEM_SERVICO',
                }),
                issues: new Field({
                    name: 'OCORRENCIAS',
                }),
                wizard: new Field({
                    name: 'WIZARD',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
                maintenanceDate: new Field({
                    name: 'DATA_ATENDIMENTO',
                }),
                initHour: new Field({
                    name: 'HORA_INICIO',
                }),
                endHour: new Field({
                    name: 'HORA_CONCLUSAO',
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                }),
                freeAddress: new Field({
                    name: 'ENDERECO_LIVRE',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                districtName: new Field({
                    name: 'NOME_BAIRRO',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                additionalReference: new Field({
                    name: 'REFERENCIA_COMPLEMENTAR',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                }),
                lat: new Field({
                    name: 'LATITUDE',
                }),
                lng: new Field({
                    name: 'LONGITUDE',
                }),
                latMaintenance: new Field({
                    name: 'LATITUDE_ATENDIMENTO',
                }),
                lngMaintenance: new Field({
                    name: 'LONGITUDE_ATENDIMENTO',
                }),
                patrolId: new Field({
                    name: 'ID_RONDA',
                }),
                reportId: new Field({
                    name: 'ID_LAUDO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveMaintenaceByServiceOrder extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAtendimentoPSPorOS',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                assetSelectedId: new Field({
                    name: 'ID_PONTO_SELECIONADO',
                }),
                maintenanceRelatedId: new Field({
                    name: 'ID_ATENDIMENTO_PS_RELACIONADO',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                serviceOrderId: new Field({
                    name: 'ID_ORDEM_SERVICO',
                }),
                serviceOrderIndex: new Field({
                    name: 'INDEX_ORDEM_SERVICO_PS',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                maintenanceDate: new Field({
                    name: 'DATA_ATENDIMENTO',
                }),
                initHour: new Field({
                    name: 'HORA_INICIO',
                }),
                endHour: new Field({
                    name: 'HORA_CONCLUSAO',
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                }),
                onlyLinked: new Field({
                    name: 'SOMENTE_VINCULACAO',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                }),
                lat: new Field({
                    name: 'LATITUDE',
                }),
                lng: new Field({
                    name: 'LONGITUDE',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveInterventionByMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'SalvarIntervencaoPorAtendimentoPS',
            type: 'SAVE',
            fields: {
                interventionId: new Field({
                    name: 'ID_INTERVENCAO',
                }),
                stockCenterRemovalId: new Field({
                    name: 'ID_CENTRO_ESTOQUE_REMOCAO',
                }),
                stockCenterOriginId: new Field({
                    name: 'ID_CENTRO_ESTOQUE_ORIGEM',
                }),
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveMaintenanceIntervention extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAtendimentoIntervencao',
            type: 'SAVE',
            fields: {
                jsonMaintenance: new Field({
                    name: 'JSON_ATENDIMENTO',
                }),
                jsonMaintenanceServiceOrder: new Field({
                    name: 'JSON_ATENDIMENTO_OS',
                }),
                jsonStockCenter: new Field({
                    name: 'JSON_CENTRO_ESTOQUE',
                }),
                jsonInterventionAssetKnown: new Field({
                    name: 'JSON_INTERVENCOES_PONTO_CONHECIDO',
                }),
                jsonInterventionUnknownAsset: new Field({
                    name: 'JSON_INTERVENCOES_PONTO_DESCONHECIDO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceDetail extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDetalhesAtendimentoPS',
            type: 'NONE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                technicalEvaluationId: new Field({
                    name: 'ID_LAUDO',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetServiceBulletinExecution extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarServicoBoletimExecucao',
            type: 'SEARCH',
            node: 'SERVICOS_BOLETIM_EXECUCAO.SERVICO_BOLETIM_EXECUCAO',
            fields: {
                bulletinExecutionId: new Field({
                    name: 'ID_BOLETIM_EXECUCAO',
                }),
                serviceBulletinExecutionIndex: new Field({
                    name: 'INDEX_SERVICO_BOLETIM_EXEC',
                }),
                serviceId: new Field({
                    name: 'ID_SERVICO',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceRelatedMaintenances extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentosFilhosRelacionadosAtendimentoPS',
            type: 'SEARCH',
            node: 'ATENDIMENTOS_PS.ATENDIMENTO_PS',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetServicesBulletinExecution extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarServicosBoletimExecucao',
            type: 'SEARCH',
            node: 'SERVICOS_BOLETIM_EXECUCAO.SERVICO_BOLETIM_EXECUCAO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                initDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_FROM'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_TO'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $autocomplete.contract,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_CLIENTE'],
                            handler: (fields) => {
                                fields.contractId.props.disabled = !fields.clientId.value;
                                fields.contractId.props['entity-params'] = {
                                    ATIVO: 1,
                                    ID_CLIENTE: fields.clientId.value,
                                };
                            },
                        },
                    ],
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.teamSimplify,
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }],
                }),
                word: new Field({
                    name: 'PALAVRA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                serviceId: new Field({
                    name: 'ID_SERVICO',
                    component: () => import('@components/materialField/MaterialField.vue'),
                    parser: (value) => (value?.length ? value[0].itemId : null),
                    props: {
                        _label: i18n.t('SERVICE'),
                        _entity: constsEntries.ENTITY_SERVICE,
                    },
                }),
                bulletinExecutionId: new Field({
                    name: 'ID_BOLETIM_EXECUCAO',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_STATUS'),
                        entity: $select.maintenanceStatus,
                        'entity-params': { ESTORNO: 1 },
                        clearable: true,
                        'clear-provider': true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    SOMENTE_ATIVOS: 1,
                                    ESTORNO: 1,
                                };
                            },
                        }],
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                    component: () => import('@components/multipleAutocomplete/MultipleAutocomplete.vue'),
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_ATRIBUTO);
                            return mapped.length > 0 ? mapped.join(',') : null;
                        }
                        return null;
                    },
                    props: {
                        _entity: $autocomplete.attribute,
                        '_entity-params': {},
                        _label: i18n.t('ATTRIBUTES'),
                        _deletableChips: true,
                    },
                }),
                reversed: new Field({
                    name: 'ATENDIMENTO_ESTORNADO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REVERSED'),
                        entity: $select.yesNoAll,
                    },
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                    },
                }),
                attributeExcel: new Field({
                    name: 'EXCEL_ATRIBUTO',
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintOperation extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarOperacoesManutencao',
            type: 'SEARCH',
            node: 'OPERACOES_MANUTENCAO.OPERACAO_MANUTENCAO',
            fields: {
                interventionId: new Field({
                    name: 'ID_INTERVENCAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteMaintOperation extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirOperacaoManutencao',
            type: 'DELETE',
            fields: {
                maintOperationId: new Field({
                    name: 'ID_OPERACAO_MANUTENCAO',
                }),
                maintId: new Field({
                    name: 'ID_MANUTENCAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteServiceBulletinExecution extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirServicoBoletimExecucao',
            type: 'DELETE',
            fields: {
                bulletinExecutionId: new Field({
                    name: 'ID_BOLETIM_EXECUCAO',
                }),
                serviceBulletinExecutionIndex: new Field({
                    name: 'INDEX_SERVICO_BOLETIM_EXEC',
                }),
                serviceId: new Field({
                    name: 'ID_SERVICO',
                }),
                workId: new Field({
                    name: 'ID_OBRA',
                }),
                versionId: new Field({
                    name: 'ID_VERSAO',
                }),
                structureId: new Field({
                    name: 'ID_ESTRUTURA',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
                notDisplaySuccessMessage: new Field({
                    name: 'NAO_EXIBIR_MSG_SUCESSO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintOperationDetail extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDetalhesOperacaoManutencao',
            type: 'NONE',
            fields: {
                maintId: new Field({
                    name: 'ID_MANUTENCAO',
                }),
                maintOperationId: new Field({
                    name: 'ID_OPERACAO_MANUTENCAO',
                }),
                maintChange: new Field({
                    name: 'ALTERACAO_MANUTENCAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UploadPhotoMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'UploadFotosAtendimento',
            type: 'SAVE',
            fields: {
                filedata: new Field({
                    name: 'filedata',
                }),
                photoType: new Field({
                    name: 'TIPO_IMAGEM',
                }),
                fileName: new Field({
                    name: 'NOME_ARQUIVO',
                }),
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UploadSavePhotoMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'UploadSalvarFotosAtendimento',
            type: 'SAVE',
            fields: {
                filedata: new Field({
                    name: 'filedata',
                }),
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                photoType: new Field({
                    name: 'TIPO_IMAGEM',
                }),
                fileName: new Field({
                    name: 'NOME_ARQUIVO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveObservationMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'SalvarObsAtendimentoPs',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GenerateCompleteReportByTicket extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioCompletoPorSolicitacao',
            type: 'SEARCH',
            node: 'PONTOS_ATENDIDOS.PONTO_ATENDIDO',
            fields: {
                excelReportInBackground: new Field({
                    name: 'RELATORIO_EXCEL_BACKGROUND',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GenerateCompleteReportByIssue extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioCompletoPorOcorrencia',
            type: 'SEARCH',
            node: 'PONTOS_ATENDIDOS.PONTO_ATENDIDO',
            fields: {
                excelReportInBackground: new Field({
                    name: 'RELATORIO_EXCEL_BACKGROUND',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class AlterStatusGroupMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'AlterarGrupoStatusAtendimento',
            type: 'SAVE',
            fields: {
                maintenances: new Field({
                    name: 'ATENDIMENTOS',
                    parser: JSON.stringify,
                }),
                statusGroupId: new Field({
                    name: 'ID_STATUS_DESTINO',
                }),
                currentStatusGroupId: new Field({
                    name: 'ID_STATUS_ORIGEM',
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                }),
                groupStatusId: new Field({
                    name: 'ID_GRUPO_STATUS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class IsMaintenanceStepActive extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPassoAtendimentoAtivo',
            type: 'SEARCH',
            node: 'PASSOS_PARQUES.PASSOS_PARQUE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAssetMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentoPontoServico',
            type: 'SEARCH',
            node: 'PONTOS_ATENDIDOS.PONTO_ATENDIDO',
            fields: {
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                initDateTime: new Field({
                    name: ['DATA_INICIO', 'HORA_INICIO'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('PERIOD_FROM'),
                        'text-field': true,
                        'return-serve': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.initDateTime.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.dateTimeToString,
                }),
                endDateTime: new Field({
                    name: ['DATA_CONCLUSAO', 'HORA_CONCLUSAO'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('PERIOD_TO'),
                        'text-field': true,
                        'return-serve': true,
                        'default-time': '23:59',
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.endDateTime.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.dateTimeToString,
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props['entity-params'] = {
                                    HABILITADO_PARQUE: undefined,
                                    HABILITADO_CALLCENTER: undefined,
                                    SOMENTE_ATRASADAS: undefined,
                                    SOMENTE_PREVENTIVAS: undefined,
                                    SOMENTE_NAO_PREVENTIVAS: undefined,
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $select.clientContracts,
                        'value-comparator': $util.comparatorNumber,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.contractId.props['entity-params'] = {
                                    ...fields.contractId.props['entity-params'],
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.search.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                serviceNumber: new Field({
                    name: 'NUM_ATENDIMENTOS',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',').replace(/\s/g, '') : null),
                    props: {
                        label: i18n.t('SERVICE_NUM'),
                    },
                }),
                identificationNumber: new Field({
                    name: 'NUMERO_IDENTIFICACAO',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',') : null),
                    props: {
                        label: i18n.t('PLAQUE'),
                    },
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $autocomplete.assistanceStatus,
                        'entity-params': { SOMENTE_ATIVOS: 1, ESTORNO: 1 },
                        clearable: true,
                        disabled: false,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_TIPO_OCORRENCIA', 'IDS_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    SOMENTE_ATIVOS: 1,
                                    ID_TIPO_OCORRENCIA: fields.issueTypeId.value,
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                    ESTORNO: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceStatusId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceMotive,
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_STATUS_ATENDIMENTO_PS', 'IDS_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_STATUS_ATENDIMENTO_PS: fields.maintenanceStatusId.value,
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceOrigin: new Field({
                    name: 'ORIGEM_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_ORIGIN'),
                        entity: $select.originMaintenance,
                        clearable: true,
                    },
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $autocomplete.assitanceSolution,
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_STATUS_ATENDIMENTO_PS', 'ID_MOTIVO_ATENDIMENTO_PS', 'IDS_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_STATUS_ATENDIMENTO_PS: fields.maintenanceStatusId.value,
                                    ID_MOTIVO_ATENDIMENTO_PS: fields.maintenanceReasonId.value,
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                isConfirm: new Field({
                    name: 'IS_CONFIRMADO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONFIRMED'),
                        entity: $select.yesNo,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.isConfirm.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.team,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                    TODAS: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                groupStatusId: new Field({
                    name: 'ID_GRUPO_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('GROUP_STEPS'),
                        entity: $autocomplete.groupStatus,
                        'entity-params': { ID_GRUPO_STATUS_ENTIDADE: 1 },
                        clearable: true,
                    },
                }),
                statusId: new Field({
                    name: 'ID_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('STEP_OF_GROUP'),
                        entity: $autocomplete.statusGroup,
                        'entity-params': {},
                        'require-params': ['ID_GRUPO_STATUS'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_GRUPO_STATUS'],
                            handler: (fields) => {
                                fields.statusId.props['entity-params'] = {
                                    ID_GRUPO_STATUS: fields.groupStatusId.value,
                                };
                            },
                        }, {
                            fieldName: 'ID_GRUPO_STATUS',
                            handler: (fields) => {
                                if (fields.groupStatusId.value == null) {
                                    fields.statusId.value = null;
                                }
                                fields.statusId.props.disabled = !fields.groupStatusId.value;
                            },
                        }],
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('AREA'),
                        entity: $select.area,
                        'clear-provider': true,
                        multiple: true,
                        '_prepend-item-select-all': true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.areaId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.arrayToString,
                }),
                hasPhoto: new Field({
                    name: 'COM_FOTOS',
                    component: 'ASelect',
                    value: -1,
                    props: {
                        label: i18n.t('HAS_PHOTOS'),
                        entity: $select.yesNoAll,
                        clearable: false,
                    },
                }),
                areaFlag: new Field({
                    name: 'FLAG_AREA',
                    component: 'ASwitch',
                    props: {
                        class: 'text-no-wrap',
                        label: i18n.t('VIEW_AREAS'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.areaFlag.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                workId: new Field({
                    name: 'ID_OBRA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CONSTRUCTION'),
                        entity: $autocomplete.project,
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                        'reload-provider': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.workId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    SOMENTE_ATIVOS: 1,
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.regions,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: ['IDS_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value || !!fields.withoutDistrict.value;
                            },
                        }],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        multiple: true,
                        entity: $select.districts,
                        '_prepend-item-select-all': true,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: ['IDS_PARQUE_SERVICO', 'ID_MUNICIPIO', 'ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value || !!fields.withoutDistrict.value;
                            },
                        }],
                }),
                withoutDistrict: new Field({
                    name: 'SEM_BAIRRO',
                    component: 'ASwitch',
                    props: {
                        class: 'text-no-wrap',
                        label: i18n.t('WITHOUT_DISTRICT'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.withoutDistrict.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                protocolId: new Field({
                    name: 'ID_PROTOCOLO',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',') : ''),
                    props: {
                        label: i18n.t('PROTOCOL'),
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.protocolId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                assetTypeId: new Field({
                    name: 'ID_TIPO_PONTO_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ASSET_TYPE'),
                        entity: $autocomplete.assetType,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                withoutForm: new Field({
                    name: 'SEM_FORMULARIO',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('WITHOUT_FORM'),
                        class: 'mr-2',
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.withoutForm.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                generatedFromTicket: new Field({
                    name: 'GERADO_POR_SOLICITACAO',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('GENERATED_FROM_REQUEST'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.generatedFromTicket.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        entity: $autocomplete.userCompany,
                        label: i18n.t('USER_COMPANY'),
                        clearable: true,
                        edit: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.userCompanyId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                allowedLocationId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATION'),
                        clearable: true,
                        entity: $select.groupingLocation,
                        'entity-params': {},
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_EMPRESA_USUARIA',
                            handler: (fields) => {
                                fields.allowedLocationId.props['entity-params'] = {
                                    ID_EMPRESA_USUARIA: fields.userCompanyId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.allowedLocationId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                priorityId: new Field({
                    name: 'ID_PRIORIDADE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('PRIORITY'),
                        clearable: true,
                        entity: $select.issueAssetPriority,
                        'entity-params': {},
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        'clear-provider': true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.priorityId.props['entity-params'] = {
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.priorityId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                withSolution: new Field({
                    name: 'COM_SOLUCAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_WITH_SOLUTION'),
                        entity: $select.yesNo,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.withSolution.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                    component: () => import('@components/multipleAutocomplete/MultipleAutocomplete.vue'),
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_ATRIBUTO);
                            return mapped.length > 0 ? mapped.join(',') : null;
                        }
                        return null;
                    },
                    props: {
                        _entity: $autocomplete.attribute,
                        '_entity-params': {},
                        _label: i18n.t('ATTRIBUTES'),
                        _deletableChips: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_TIPO_PONTO_SERVICO',
                            handler: (fields) => {
                                fields.attributeFilter.props['entity-params'] = {
                                    ID_TIPO_PONTO_SERVICO: fields.assetTypeId.value,
                                };
                            },
                        },
                    ],
                }),
                getReopened: new Field({
                    name: 'CONSULTAR_REABERTAS',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('REOPEN_MAINTENANCE_ONLY'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.getReopened.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                initDistance: new Field({
                    name: 'DISTANCIA_INICIAL',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MIN_DISTANCE'),
                        type: 'number',
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.initDistance.props.disabled = !fields.worksiteId.value || !!fields.withSolution.value;
                            },
                        }],
                }),
                endDistance: new Field({
                    name: 'DISTANCIA_FINAL',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAX_DISTANCE'),
                        type: 'number',
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.endDistance.props.disabled = !fields.worksiteId.value || !!fields.withSolution.value;
                            },
                        }],
                }),
                withoutLocation: new Field({
                    name: 'SEM_LOCALIZACAO',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('ASSETS_WITHOUT_LOCATION'),
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.withoutLocation.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.booleanToNumber,
                }),
                employeeName: new Field({
                    name: 'NOME_COLABORADOR',
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.employeeName.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                    },
                }),
                reversed: new Field({
                    name: 'ATENDIMENTO_ESTORNADO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REVERSED'),
                        entity: $select.yesNoAll,
                    },
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                locationId: new Field({
                    name: 'ID_LOCALIZACAO',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                mobile: new Field({
                    name: 'MOBILE',
                    parser: $util.booleanToNumber,
                }),
                onlyAsset: new Field({
                    name: 'SOMENTE_PONTO_SERVICO',
                    parser: $util.booleanToNumber,
                }),
                fullReport: new Field({
                    name: 'RELATORIO_COMPLETO',
                    parser: $util.booleanToNumber,
                }),
                byTicket: new Field({
                    name: 'POR_SOLICITACAO',
                    parser: $util.booleanToNumber,
                }),
                photos: new Field({
                    name: 'IMAGENS',
                    parser: $util.booleanToNumber,
                }),
                previousTicketId: new Field({
                    name: 'ID_SOLICITACAO_ANTERIOR',
                }),
                previousTicketIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS_ANTERIOR',
                }),
                previousIssueId: new Field({
                    name: 'ID_OCORRENCIA_ANTERIOR',
                }),
                previousIssueIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS_ANTERIOR',
                }),
                issueStatusId: new Field({
                    name: 'ID_STATUS_OCORRENCIA',
                }),
                getMaintenance: new Field({
                    name: 'CONSULTA_ATENDIMENTO',
                    parser: $util.booleanToNumber,
                }),
                pdfReportDetailed: new Field({
                    name: 'RELATORIO_PDF_DETALHADO',
                    parser: $util.booleanToNumber,
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
                reportId: new Field({
                    name: 'ID_LAUDO',
                }),
                maintenanceReportByAttribute: new Field({
                    name: 'RELATORIO_ATENDIMENTO_POR_ATRIBUTO',
                }),
                maintenanceReportConcise: new Field({
                    name: 'RELATORIO_ATENDIMENTO_RESUMIDO',
                    parser: $util.booleanToNumber,
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
                polygonPoints: new Field({
                    name: 'PONTOS_POLIGONO',
                }),
                uuid: new Field({
                    name: 'UUID',
                }),
            },
        });
    }
    async requireRequest() {
        return Promise.resolve(!!this.fields.worksiteId.value);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceDataMonitor extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosAtendimentoPSMonitor',
            type: 'NONE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                initDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                }),
                allowedLocationId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetFormMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarFormulariosAtendimento',
            type: 'SEARCH',
            node: 'FORMULARIOS.FORMULARIO',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteFormMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirFormulario',
            type: 'DELETE',
            fields: {
                formId: new Field({
                    name: 'ID_FORMULARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveFormMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'SalvarFormularioAtendimento',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO_PS',
                }),
                ticketIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                attributeSchemeId: new Field({
                    name: 'ID_ESQUEMA_ATRIBUTOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetFormMaintenanceDetail extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDetalhesFormularioAtendimento',
            type: 'NONE',
            fields: {
                formId: new Field({
                    name: 'ID_FORMULARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ConfirmForm extends CallCommand {
    constructor() {
        super({
            name: 'ConfirmarFormulario',
            type: 'NONE',
            fields: {
                formId: new Field({
                    name: 'ID_FORMULARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ConfirmMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConfirmarAtendimentoPS',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class CheckIfHasAttributes extends CallCommand {
    constructor() {
        super({
            name: 'PontoServicoPossuiAtributosNaVersaoAtual',
            type: 'SEARCH',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DisconfirmMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'DesconfirmarAtendimentoPS',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirAtendimentoPS',
            type: 'DELETE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPhotoMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultaAtendimentoFotos',
            type: 'SEARCH',
            node: 'FOTOS.FOTO',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeletePhotoMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirFotoAtendimento',
            type: 'DELETE',
            fields: {
                fileId: new Field({
                    name: 'ID_ARQUIVO',
                }),
                setFileId: new Field({
                    name: 'ID_CONJUNTO_ARQUIVOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SavePhotoMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'AtendimentoUploadFotos',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                imageType: new Field({
                    name: 'TIPO_IMAGEM',
                }),
                fileName: new Field({
                    name: 'NOME_ARQUIVO',
                }),
                filePath: new Field({
                    name: 'PATH_ARQUIVO',
                }),
                params: new Field({
                    name: 'PARAMS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetEventMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarEventosAtendimento',
            type: 'SEARCH',
            node: 'EVENTOS.EVENTO',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceStockHandling extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentoMovimentoEstoque',
            type: 'NONE',
            fields: {
                stockHandlingId: new Field({
                    name: 'ID_MOVIMENTO_ESTOQUE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetActionAfterMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAcaoPosAtendimento',
            type: 'SEARCH',
            node: 'ACOES.ACAO',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ChangeActionAfterMaintenancePriority extends CallCommand {
    constructor() {
        super({
            name: 'AlteraPrioridadeAcaoPosAtendimento',
            type: 'SAVE',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
                order: new Field({
                    name: 'ORDEM',
                }),
                bigger: new Field({
                    name: 'MAIOR',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteActionAfterMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirAcaoPosAtendimento',
            type: 'DELETE',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveActionAfterMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAcaoPosAtendimento',
            type: 'SAVE',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
                issueOriginType: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                }),
                emailForwarding: new Field({
                    name: 'EMAIL_ENCAMINHAMENTO',
                }),
                statusMaintenanceId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                }),
                reasonMaintenanceId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                }),
                solutionMaintenanceId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                attributesSchemaId: new Field({
                    name: 'ID_ESQUEMA_ATRIBUTOS',
                }),
                sendFormAsSurvey: new Field({
                    name: 'ENVIAR_FORMULARIO_COMO_PESQUISA',
                }),
                satisfactionSurveyOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO',
                }),
                sendDetails: new Field({
                    name: 'ENVIAR_DETALHES',
                    parser: $util.booleanToNumber,
                }),
                sendBeforePhotos: new Field({
                    name: 'ENVIAR_FOTOS_ANTES',
                    parser: $util.booleanToNumber,
                }),
                sendDuringPhotos: new Field({
                    name: 'ENVIAR_FOTOS_DURANTE',
                    parser: $util.booleanToNumber,
                }),
                sendAfterPhotos: new Field({
                    name: 'ENVIAR_FOTOS_DEPOIS',
                    parser: $util.booleanToNumber,
                }),
                automaticallyAuthorize: new Field({
                    name: 'AUTORIZAR_AUTOMATICAMENTE',
                }),
                reopeningType: new Field({
                    name: 'TIPO_REABERTURA',
                }),
                termTreatment: new Field({
                    name: 'TRATAMENTO_PRAZO',
                }),
                newPriority: new Field({
                    name: 'NOVA_PRIORIDADE',
                }),
                updateTicketAttributes: new Field({
                    name: 'ATUALIZAR_ATRIBUTOS_SOLICITACAO',
                }),
                copyTicketAttributes: new Field({
                    name: 'COPIAR_ATRIBUTOS_SOLICITACAO',
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                    parser: $util.attributesToString,
                }),
                maintenanceAttributes: new Field({
                    name: 'ATRIBUTOS_ATENDIMENTO',
                    parser: $util.attributesToString,
                }),
                useDeadlineWindows: new Field({
                    name: 'UTILIZAR_JANELAS_PRAZO',
                    parser: $util.booleanToNumber,
                }),
                overwritePriorityResquestServiceType: new Field({
                    name: 'SOBRESCREVER_PRIORIDADE_TIPO_SERVICO_SOLICITACAO',
                    parser: $util.booleanToNumber,
                }),
                maxSequentialReopens: new Field({
                    name: 'MAXIMO_REABERTURAS_SEQUENCIAIS',
                }),
                attributesCopy: new Field({
                    name: 'ATRIBUTOS_COPIAR',
                    parser: function (value) {
                        let json = '';
                        value.forEach(attribute => {
                            if (json !== '') {
                                json = `${json};`;
                            }
                            if (attribute?.ID_ATRIBUTO != null) {
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                json = `${json}${attribute.ID_ATRIBUTO}`;
                            }
                        });
                        return json;
                    },
                }),
                useRelatedIssueForSeparateService: new Field({
                    name: 'UTILIZAR_OCORRENCIA_RELACIONADA_PARA_ATENDIMENTO_AVULSO',
                }),
                copyContractOriginalIssue: new Field({
                    name: 'COPIAR_CONTRATO_OCORRENCIA_ORIGINAL',
                }),
                keepOriginalProtocolRef: new Field({
                    name: 'MANTER_REFERENCIA_PROCOTOLO_ORIGINAL',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceActionOperation extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarOperacaoAcaoAtendimento',
            type: 'SEARCH',
            node: 'OPERACOES.OPERACAO',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteMaintenanceActionOperation extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirOperacaoAcaoAtendimento',
            type: 'DELETE',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
                familyId: new Field({
                    name: 'ID_FAMILIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveMaintenanceActionOperation extends CallCommand {
    constructor() {
        super({
            name: 'SalvarOperacaoAcaoAtendimento',
            type: 'SAVE',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
                familyId: new Field({
                    name: 'ID_FAMILIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceDeadline extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentoPrazo',
            type: 'SEARCH',
            node: 'DETALHE.OCORRENCIA',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                conclusionDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props['entity-params'] = {
                                    HABILITADO_PARQUE: -1,
                                    HABILITADO_CALLCENTER: -1,
                                    HABILITADO_CI: -1,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    SOMENTE_ATIVOS: 1,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_MUNICIPIO', 'ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_MUNICIPIO: fields.cityId.value,
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        },
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO', 'ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value || (!fields.cityId.value && !fields.regionId.value);
                                if (fields.districtId.props.disabled) {
                                    fields.districtId.value = null;
                                }
                            },
                        },
                    ],
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSatisfactionSurvey extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPesquisaSatisfacao',
            type: 'SEARCH',
            node: 'PESQUISAS.PESQUISA',
            fields: {
                worksiteIds: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'), entity: $select.worksites, multiple: true, 'entity-params': { ATIVO: 1 },
                    },
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    props: {
                        clearable: true, label: i18n.t('SEARCH'),
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_FROM'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_TO'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                surveyIds: new Field({
                    name: 'IDS_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GenerateSurveySamples extends CallCommand {
    constructor() {
        super({
            name: 'GerarAmostrasPesquisa',
            type: 'NONE',
            fields: {
                worksiteIds: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                startDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                }),
                issueTypeId: new Field({
                    name: 'IDS_TIPO_OCORRENCIA',
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                }),
                ticketCategoryId: new Field({
                    name: 'ID_CATEGORIA_SOLICITACAO',
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO',
                }),
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                randomSamples: new Field({
                    name: 'AMOSTRAS_ALEATORIAS',
                }),
                includeVinculatedSamples: new Field({
                    name: 'INCLUIR_AMOSTRAS_VINCULADAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GenerateSurvey extends CallCommand {
    constructor() {
        super({
            name: 'GerarPesquisa',
            type: 'SAVE',
            config: { type: 'auth', customMessage: i18n.t('AUTHENTICATION_REQUIRED') },
            fields: {
                surveyDescription: new Field({
                    name: 'DESC_PESQUISA',
                }),
                worksiteIds: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                originTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM',
                }),
                formId: new Field({
                    name: 'ID_FORMULARIO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                startDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                }),
                issueTypeId: new Field({
                    name: 'IDS_TIPO_OCORRENCIA',
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                }),
                ticketCategoryId: new Field({
                    name: 'ID_CATEGORIA_SOLICITACAO',
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO',
                }),
                limit: new Field({
                    name: 'LIMIT',
                }),
                samplesIds: new Field({
                    name: 'IDS_AMOSTRAS',
                }),
                randomSamples: new Field({
                    name: 'AMOSTRAS_ALEATORIAS',
                }),
                includeVinculatedSamples: new Field({
                    name: 'INCLUIR_AMOSTRAS_VINCULADAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class AddSamplesSurvey extends CallCommand {
    constructor() {
        super({
            name: 'AdicionarAmostrasPesquisa',
            type: 'SAVE',
            config: { type: 'auth', customMessage: i18n.t('AUTHENTICATION_REQUIRED') },
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                worksiteIds: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                startDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                }),
                issueTypeId: new Field({
                    name: 'IDS_TIPO_OCORRENCIA',
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                }),
                ticketCategoryId: new Field({
                    name: 'ID_CATEGORIA_SOLICITACAO',
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO',
                }),
                limit: new Field({
                    name: 'LIMIT',
                }),
                samplesIds: new Field({
                    name: 'IDS_AMOSTRAS',
                }),
                randomSamples: new Field({
                    name: 'AMOSTRAS_ALEATORIAS',
                }),
                includeVinculatedSamples: new Field({
                    name: 'INCLUIR_AMOSTRAS_VINCULADAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteSurvey extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirPesquisa',
            type: 'DELETE',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSurveySamples extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAmostrasPesquisa',
            type: 'SEARCH',
            node: 'AMOSTRAS.AMOSTRA',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                sampleId: new Field({
                    name: 'ID_AMOSTRA_PESQUISA',
                }),
                generateExcel: new Field({
                    name: 'GERAR_EXCEL',
                }),
                generatePDF: new Field({
                    name: 'GERAR_PDF',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class VerifyClaimantCode extends CallCommand {
    constructor() {
        super({
            name: 'ValidarCodigoReclamanteOcorrencia',
            type: 'NONE',
            fields: {
                sampleId: new Field({
                    name: 'ID_AMOSTRA',
                }),
                claimantCode: new Field({
                    name: 'COD_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSurveySamplesEmail extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAmostrasPesquisaEmail',
            type: 'SEARCH',
            node: 'AMOSTRAS.AMOSTRA',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                sampleId: new Field({
                    name: 'ID_AMOSTRA_PESQUISA',
                }),
                generateExcel: new Field({
                    name: 'GERAR_EXCEL',
                }),
                generatePDF: new Field({
                    name: 'GERAR_PDF',
                }),
                claimantCode: new Field({
                    name: 'COD_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSurveySamplesSelection extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAmostrasSelecaoPesquisa',
            type: 'SEARCH',
            node: 'AMOSTRAS.AMOSTRA',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                originTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                startDate: new Field({
                    name: 'DATA_INICIAL',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                }),
                ticketCategoryId: new Field({
                    name: 'ID_CATEGORIA_SOLICITACAO',
                }),
                includeVinculatedSamples: new Field({
                    name: 'INCLUIR_AMOSTRAS_VINCULADAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveSurveySample extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAmostraPesquisa',
            type: 'SAVE',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                sampleId: new Field({
                    name: 'ID_AMOSTRA_PESQUISA',
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                }),
                sampleStatusId: new Field({
                    name: 'ID_STATUS_AMOSTRA',
                }),
                observations: new Field({
                    name: 'OBS',
                }),
                claimantCode: new Field({
                    name: 'COD_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GenerateFileSetSurveySample extends CallCommand {
    constructor() {
        super({
            name: 'GerarConjuntoArquivoAmostraPesquisa',
            type: 'SAVE',
            fields: {
                sampleId: new Field({
                    name: 'ID_AMOSTRA_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveSurvey extends CallCommand {
    constructor() {
        super({
            name: 'SalvarPesquisa',
            type: 'SAVE',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                originTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM',
                }),
                formId: new Field({
                    name: 'ID_FORMULARIO',
                }),
                surveyDescription: new Field({
                    name: 'DESC_PESQUISA',
                }),
                erase: new Field({
                    name: 'APAGAR',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ApproveSurveyElaboration extends CallCommand {
    constructor() {
        super({
            name: 'AprovarElaboracaoPesquisa',
            type: 'SAVE',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class CancelSurveyElaboration extends CallCommand {
    constructor() {
        super({
            name: 'CancelarElaboracaoPesquisa',
            type: 'SAVE',
            config: { type: 'auth', customMessage: i18n.t('AUTHENTICATION_REQUIRED') },
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EndSurvey extends CallCommand {
    constructor() {
        super({
            name: 'FinalizarPesquisa',
            type: 'SAVE',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                conclusionDate: new Field({
                    name: 'DATA_CONCLUSAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSurveySampleAttributes extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtributosPorAmostraPesquisa',
            type: 'SEARCH',
            fields: {
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
                sampleId: new Field({
                    name: 'ID_AMOSTRA_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenancesMonitorForm extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioAcompanhamentoAtendimentos',
            type: 'SEARCH',
            node: 'ATENDIMENTOS.ATENDIMENTO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                word: new Field({
                    name: 'PALAVRA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                        clearable: true,
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                withMaintenanceOnly: new Field({
                    name: 'APENAS_COM_ATENDIMENTO',
                    component: 'AAutocomplete',
                    value: 0,
                    props: {
                        label: i18n.t('SHOW_MAINTENANCES'),
                        entity: $select.yesNo,
                        clearable: false,
                    },
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_STATUS'),
                        entity: $autocomplete.assistanceStatus,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    ATIVO: 1,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: ['APENAS_COM_ATENDIMENTO'],
                            handler: (fields) => {
                                fields.maintenanceStatusId.props.disabled = !fields.withMaintenanceOnly.value;
                                if (fields.maintenanceStatusId.props.disabled) {
                                    fields.maintenanceStatusId.value = null;
                                }
                            },
                        },
                    ],
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
                attributesFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceStatusAsset extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarStatusAtendimentoPontoServico',
            type: 'SEARCH',
            node: 'PONTOS_STATUS_ATENDIMENTO.PONTO_STATUS_ATENDIMENTO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    props: {
                        clearable: true, label: i18n.t('SEARCH'),
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                    value: $moment().subtract(1, 'months').format('DD/MM/YYYY'),
                    dependsOn: [
                        {
                            fieldName: 'STATUS',
                            handler: (fields) => {
                                if (fields.status.value === DonePendingAll[0].VALUE) {
                                    fields.startDate.props.label = i18n.t('INITIAL_DATE');
                                }
                                else if (fields.status.value === DonePendingAll[1].VALUE) {
                                    fields.startDate.props.label = `${i18n.t('INITIAL_ISSUE_DATE')} ${i18n.t('OR').toLowerCase()} ${i18n.t('SO')}`;
                                }
                                else if (fields.status.value === DonePendingAll[2].VALUE) {
                                    fields.startDate.props.label = i18n.t('MAINTENANCE_INITIAL_DATE');
                                }
                            },
                        },
                    ],
                }),
                endDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                    value: $moment().format('DD/MM/YYYY'),
                    dependsOn: [
                        {
                            fieldName: 'STATUS',
                            handler: (fields) => {
                                if (fields.status.value === DonePendingAll[0].VALUE) {
                                    fields.endDate.props.label = i18n.t('ENDING_DATE');
                                }
                                else if (fields.status.value === DonePendingAll[1].VALUE) {
                                    fields.endDate.props.label = `${i18n.t('ENDING_ISSUE_DATE')} ${i18n.t('OR').toLowerCase()} ${i18n.t('SO')}`;
                                }
                                else if (fields.status.value === DonePendingAll[2].VALUE) {
                                    fields.endDate.props.label = i18n.t('MAINTENANCE_ENDING_DATE');
                                }
                            },
                        },
                    ],
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $select.clientContracts,
                        'value-comparator': $util.comparatorNumber,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.contractId.props['entity-params'] = {
                                    ...fields.contractId.props['entity-params'],
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'ASelect',
                    parser: $util.arrayToString,
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props['entity-params'] = {
                                    SOMENTE_PREVENTIVAS: 0,
                                    SOMENTE_ATRASADAS: 0,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    HABILITADO_PARQUE: null,
                                    SOMENTE_NAO_PREVENTIVAS: null,
                                    HABILITADO_CALLCENTER: null,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                status: new Field({
                    name: 'STATUS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('SITUATION'),
                        entity: $select.donePendingAll,
                        clearable: false,
                    },
                    value: 0,
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    SOMENTE_ATIVOS: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                regions: new Field({
                    name: 'REGIOES',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                    value: [],
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: (fields) => {
                                fields.regions.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                    REGIAO_VAZIA: true,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regions.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $select.districts,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO', 'REGIOES'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                    IDS_REGIOES: fields.regions.value,
                                };
                            },
                        },
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO', 'REGIOES'],
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value || !fields.cityId.value;
                                if (fields.districtId.props.disabled) {
                                    fields.districtId.value = null;
                                }
                            },
                        },
                    ],
                }),
                publicPlace: new Field({
                    name: 'LOGRADOURO',
                    component: 'ACombobox',
                    parser: (value) => (value ? (value.NOME_LOGRADOURO_COMPLETO ?? value) : null),
                    props: {
                        label: i18n.t('PUBLIC_PLACE'),
                        entity: $autocomplete.publicPlace,
                        clearable: true,
                        'reload-provider': true,
                        'item-text': (item) => (item ? item.NOME_LOGRADOURO_COMPLETO : null),
                        '_disabled-clear-not-found': true,
                        _minToSearch: 2,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_BAIRRO',
                            handler: function () {
                                this.fields.publicPlace.props['entity-params'] = { ID_BAIRRO: this.fields.districtId.value };
                            },
                        },
                    ],
                }),
                publicPlaceId: new Field({
                    name: 'ID_LOGRADOURO',
                    dependsOn: [
                        {
                            fieldName: 'LOGRADOURO',
                            handler: function () {
                                this.fields.publicPlaceId.value = this.fields.publicPlace.value?.ID_LOGRADOURO ?? null;
                            },
                        },
                    ],
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('AREA'),
                        entity: $autocomplete.area,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_STATUS'),
                        entity: $autocomplete.assistanceStatus,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    ATIVO: 1,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regions.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                minRemainingTime: new Field({
                    name: 'PRAZO_RESTANTE_MINIMO',
                }),
                maxRemainingTime: new Field({
                    name: 'PRAZO_RESTANTE_MAXIMO',
                }),
                remainingTime: new Field({
                    name: ['PRAZO_RESTANTE_MINIMO', 'PRAZO_RESTANTE_MAXIMO', 'GRANDEZA_PRAZO'],
                    component: () => import('@components/remainingTimeSlide/RemainingTimeSlide.vue'),
                    parser: (value) => (value?.sliderRange && value.sliderRange[0] !== value.sliderRange[1] ? [value.sliderRange[0], value.sliderRange[1], value.remainingTimeType] : []),
                    props: {
                        _maxRemainingTime: 23,
                        _minRemainingTime: 0,
                        _daysLabel: i18n.t('WILL_DELAY_DAYS'),
                        _hoursLabel: i18n.t('WILL_DELAY_HOURS'),
                    },
                }),
                maintenanceOrigin: new Field({
                    name: 'ORIGEM_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_ORIGIN'),
                        entity: $select.originMaintenance,
                        clearable: true,
                    },
                }),
                issueTypeOriginId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ORIGIN'),
                        entity: $autocomplete.issueTypeOrigin,
                        'entity-params': { SEM_RECLAMANTE: 0 },
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                authorizedIssues: new Field({
                    name: 'OCORRENCIAS_AUTORIZADAS',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('AUTHORIZED_ISSUES'),
                    },
                }),
                ticketType: new Field({
                    name: 'TIPO_SOLICITACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TICKET_TYPE'),
                        entity: $select.monitorTicketType,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'ASelect',
                    parser: $util.arrayToString,
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $autocomplete.teamSimplify,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                showPhotos: new Field({
                    name: 'EXIBIR_FOTOS',
                }),
                lateOnly: new Field({
                    name: 'SOMENTE_ATRASADOS',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    value: false,
                    props: {
                        label: i18n.t('LATE_ONLY'),
                    },
                }),
                minDelay: new Field({
                    name: 'ATRASADO_MINIMO',
                }),
                maxDelay: new Field({
                    name: 'ATRASADO_MAXIMO',
                }),
                delay: new Field({
                    name: ['ATRASADO_MINIMO', 'ATRASADO_MAXIMO', 'GRANDEZA_ATRASADO'],
                    component: () => import('@components/remainingTimeSlide/RemainingTimeSlide.vue'),
                    parser: (value) => (value?.sliderRange && value.sliderRange[0] !== value.sliderRange[1] ? [value.sliderRange[0], value.sliderRange[1], value.remainingTimeType] : []),
                    props: {
                        _maxRemainingTime: 23,
                        _minRemainingTime: 0,
                        _daysLabel: i18n.t('DAYS_LATE'),
                        _hoursLabel: i18n.t('HOURS_LATE'),
                    },
                    dependsOn: [
                        {
                            fieldName: 'SOMENTE_ATRASADOS',
                            handler: (fields) => {
                                fields.delay.props._disabled = !fields.lateOnly.value;
                                if (fields.delay.props._disabled) {
                                    fields.delay.value = { enable: false, remainingTimeType: 1, sliderRange: [0, 0] };
                                }
                            },
                        },
                    ],
                }),
                daysLate: new Field({
                    name: 'DIAS_EM_ATRASO',
                    component: 'ATextField',
                    props: {
                        clearable: true,
                        label: i18n.t('DAYS_LATE'),
                        type: 'Number',
                    },
                    dependsOn: [
                        {
                            fieldName: 'SOMENTE_ATRASADOS',
                            handler: (fields) => {
                                fields.daysLate.props.disabled = !fields.lateOnly.value || fields.delay.value.enable;
                            },
                        },
                        {
                            fieldName: ['ATRASADO_MINIMO', 'ATRASADO_MAXIMO', 'GRANDEZA_ATRASADO'],
                            handler: (fields) => {
                                fields.daysLate.props.disabled = fields.delay.value.enable || !fields.lateOnly.value;
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                grouping: new Field({
                    name: 'AGRUPAMENTO',
                }),
                assetIssuePriorityAbbreviation: new Field({
                    name: 'SIGLA_PRIORIDADE_PONTO_OCORR',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('PRIORITY'),
                        entity: $autocomplete.assetIssuePriority,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetIssuePriorityAbbreviation.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetIssuePriorityAbbreviation.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATION'),
                        entity: $select.groupingLocation,
                        'entity-params': {},
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_EMPRESA_USUARIA',
                            handler: (fields) => {
                                fields.locationGroupingId.props['entity-params'] = {
                                    ID_EMPRESA_USUARIA: fields.userCompanyId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.locationGroupingId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                maintenanceStartDate: new Field({
                    name: 'DATA_INICIO_ATENDIMENTO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_MAINTENANCE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                maintenanceEndDate: new Field({
                    name: 'DATA_CONCLUSAO_ATENDIMENTO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_MAINTENANCE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                issueStartDate: new Field({
                    name: 'DATA_INICIO_OCORRENCIA',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_ISSUE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                issueEndDate: new Field({
                    name: 'DATA_CONCLUSAO_OCORRENCIA',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_ISSUE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                serviceOrderStartDate: new Field({
                    name: 'DATA_INICIO_OS',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_OS_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                serviceOrderEndDate: new Field({
                    name: 'DATA_CONCLUSAO_OS',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_OS_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                complainer: new Field({
                    name: 'RECLAMANTE',
                    component: 'ATextField',
                    props: {
                        clearable: true, label: i18n.t('COMPLAINER'),
                    },
                }),
                hasInstalationPhoto: new Field({
                    name: 'POSSUI_FOTO_INSTALACAO',
                }),
                manufacturerInstalationId: new Field({
                    name: 'ID_FABRICANTE_INSTALACAO',
                }),
                familyInstalationId: new Field({
                    name: 'ID_FAMILIA_INSTALACAO',
                }),
                materialInstalationCode: new Field({
                    name: 'COD_MATERIAL_INSTALACAO',
                }),
                hasPhotoRemoval: new Field({
                    name: 'POSSUI_FOTO_RETIRADA',
                }),
                manufacturerRemovalId: new Field({
                    name: 'ID_FABRICANTE_RETIRADA',
                }),
                familyRemovalId: new Field({
                    name: 'ID_FAMILIA_RETIRADA',
                }),
                materialRemovalCode: new Field({
                    name: 'COD_MATERIAL_RETIRADA',
                }),
                insideArea: new Field({
                    name: 'DENTRO_DE_AREA',
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                        _filter: false,
                    },
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                summaryReportGrouping: new Field({
                    name: 'AGRUPAMENTO_RELATORIO_RESUMIDO',
                }),
                selectedColumns: new Field({
                    name: 'COLUNAS_SELECIONADAS',
                }),
                patrolId: new Field({
                    name: 'ID_RONDA',
                }),
                areaFlag: new Field({
                    name: 'FLAG_AREA',
                }),
                withoutFormOnly: new Field({
                    name: 'SOMENTE_SEM_FORMULARIO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                grouped: new Field({
                    name: 'AGRUPADO',
                }),
                version: new Field({
                    name: 'VERSAO',
                    value: 2,
                }),
                excelReport: new Field({
                    name: 'RELATORIO_EXCEL',
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
                protocolId: new Field({
                    name: 'ID_PROTOCOLO',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',') : ''),
                    props: {
                        label: i18n.t('PROTOCOL'),
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.protocolId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                showDocumentNumber: new Field({
                    name: 'MOSTRAR_NUMERO_DOCUMENTO',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('SHOW_DOCUMENT_NUMBER'),
                    },
                }),
                documentNumber: new Field({
                    name: 'NUMERO_DOCUMENTO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('DOCUMENT_NUMBER'),
                        clearable: true,
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCommandPermission extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPermissaoComando',
            type: 'NONE',
            fields: {
                commandName: new Field({
                    name: 'NOME_COMANDO_VERIFICACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAttributesByMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtributosPorAtendimento',
            type: 'NONE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAttributesByStatusGroup extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtributosByStatusGrupo',
            type: 'NONE',
            fields: {
                groupStatusId: new Field({
                    name: 'ID_GRUPO_STATUS',
                }),
                statusId: new Field({
                    name: 'ID_STATUS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetStepHistoryMaintenace extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoPassosAtendimentoPs',
            type: 'NONE',
            node: 'HISTORICOS.HISTORICO',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetStepHistoryMaintenaceDetail extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoPassoAtendimentoDetalhado',
            type: 'NONE',
            node: 'ATRIBUTOS.ATRIBUTO',
            fields: {
                attributeHistoryId: new Field({
                    name: 'ID_HISTORICO_ATRIBUTO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DefineMaintenanceRelated extends CallCommand {
    constructor() {
        super({
            name: 'DefinirAtendimentoPSRelacionado',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                relatedMaintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS_RELACIONADO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPhotosMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarFotosAtendimento',
            type: 'NONE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                setFileId: new Field({
                    name: 'ID_CONJUNTO_ARQUIVOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveOperationKnownAssetMaint extends CallCommand {
    constructor() {
        super({
            name: 'SalvarOperacaoManutencaoPontoConhecido',
            type: 'SAVE',
            fields: {
                maintOperationId: new Field({
                    name: 'ID_OPERACAO_MANUTENCAO',
                }),
                maintId: new Field({
                    name: 'ID_MANUTENCAO',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
                itemId: new Field({
                    name: 'ID_ITEM',
                }),
                structureAssetItemId: new Field({
                    name: 'ID_ITEM_ESTRUTURA_PS',
                }),
                parentStructureAssetItemId: new Field({
                    name: 'ID_ITEM_ESTRUTURA_PS_PAI',
                }),
                structureAssetItemCode: new Field({
                    name: 'COD_ITEM_ESTRUTURA_PS',
                }),
                manufacturerItemId: new Field({
                    name: 'ID_ITEM_FABRICANTE',
                }),
                warrantyDate: new Field({
                    name: 'DATA_GARANTIA',
                }),
                withDefective: new Field({
                    name: 'COM_DEFEITO',
                }),
                missingItem: new Field({
                    name: 'ITEM_DESAPARECIDO',
                }),
                inContractWarranty: new Field({
                    name: 'DENTRO_GARANTIA_CONTRATUAL',
                }),
                warrantyContratualDate: new Field({
                    name: 'VENCIMENTO_GARANTIA_CONTRATUAL',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                notAddLinkedService: new Field({
                    name: 'NAO_ADICIONAR_SERVICO_VINCULADO',
                }),
                materialGuaranteeLotId: new Field({
                    name: 'ID_LOTE_GARANTIA_MATERIAL',
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveOperationUnknownAssetMaint extends CallCommand {
    constructor() {
        super({
            name: 'SalvarOperacaoManutencaoPontoDesconhecido',
            type: 'SAVE',
            fields: {
                maintOperationId: new Field({
                    name: 'ID_OPERACAO_MANUTENCAO',
                }),
                maintId: new Field({
                    name: 'ID_MANUTENCAO',
                }),
                itemId: new Field({
                    name: 'ID_ITEM',
                }),
                familyId: new Field({
                    name: 'ID_FAMILIA',
                }),
                quantity: new Field({
                    name: 'QUANTIDADE',
                }),
                consumption: new Field({
                    name: 'CONSUMO',
                }),
                operation: new Field({
                    name: 'OPERACAO',
                }),
                withDefective: new Field({
                    name: 'COM_DEFEITO',
                }),
                missingItem: new Field({
                    name: 'ITEM_DESAPARECIDO',
                }),
                inContractWarranty: new Field({
                    name: 'DENTRO_GARANTIA_CONTRATUAL',
                }),
                warrantyContratualDate: new Field({
                    name: 'VENCIMENTO_GARANTIA_CONTRATUAL',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                notAddLinkedService: new Field({
                    name: 'NAO_ADICIONAR_SERVICO_VINCULADO',
                }),
                materialGuaranteeLotId: new Field({
                    name: 'ID_LOTE_GARANTIA_MATERIAL',
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ExportMaintenancePhotoCompacted extends CallCommand {
    constructor() {
        super({
            name: 'ExportFotosAtendimentoCompactado',
            type: 'FILE',
            config: { type: 'file' },
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                extensionParser: new Field({
                    name: 'EXTENSION_PARSER',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceReasonsByPeriod extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarMotivosAtendimentoPorPeriodo',
            type: 'SEARCH',
            node: 'MOTIVOS.MOTIVO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                                if (!fields.worksiteId.value) {
                                    fields.regionId.value = null;
                                }
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_REGIAO: fields.regionId.value,
                                };
                                fields.districtId.props.disabled = !fields.regionId.value;
                                if (!fields.regionId.value) {
                                    fields.districtId.value = null;
                                }
                            },
                        },
                    ],
                }),
                startTerm: new Field({
                    name: 'INICIO_VIGENCIA',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('VALIDITY_START'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endTerm: new Field({
                    name: 'TERMINO_VIGENCIA',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('VALIDITY_END'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetRecurrentMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentosRecorrentes',
            type: 'SEARCH',
            node: 'ATENDIMENTO_RECORRENTE',
            fields: {
                groupByFamily: new Field({
                    name: 'AGRUPAR_POR_FAMILIA',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('GROUP_BY_ENTITY', { entity: i18n.t('FAMILY').toLowerCase() }),
                        'true-value': 1,
                        'false-value': 0,
                    },
                    value: 0,
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                worksitesId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        clearable: true,
                        multiple: true,
                    },
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $select.maintenanceReasons,
                        clearable: true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                        'entity-params': {},
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksitesId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksitesId.value,
                                };
                            },
                        },
                    ],
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                        'enable-relative-picker': true,
                    },
                }),
                operatorQuantity: new Field({
                    name: 'OPERADOR_QUANTIDADE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('OPERATOR'),
                        entity: $select.greaterThanEqualsOperator,
                    },
                }),
                maintenanceQuantity: new Field({
                    name: 'QUANTIDADE_ATENDIMENTO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('QUANTITY'),
                        type: 'number',
                    },
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $select.maintenanceSolutions,
                        clearable: true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                        'entity-params': {},
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksitesId.value,
                                };
                            },
                        }],
                }),
                isConfirm: new Field({
                    name: 'IS_CONFIRMADO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONFIRMED'),
                        entity: $select.yesNo,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.isConfirm.props.disabled = this.fields.worksitesId.value.length === 0;
                            },
                        }],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $select.districts,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.districtId.props.disabled = this.fields.worksitesId.value.length === 0;
                            },
                        }],
                }),
                requestType: new Field({
                    name: 'TIPO_CONSULTA',
                }),
                issueOriginType: new Field({
                    name: 'TIPO_ORIGEM_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ISSUE_ORIGIN'),
                        entity: $select.issueTypeOrigin,
                        clearable: true,
                        multiple: true,
                        'entity-params': { SEM_RECLAMANTE: 0 },
                    },
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                        clearable: true,
                        'clear-provider': true,
                    },
                    value: null,
                }),
                groupingLocationId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $select.groupingLocation,
                        clearable: true,
                    },
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $autocomplete.teamSimplify,
                        clearable: true,
                    },
                }),
                report: new Field({
                    name: 'RELATORIO',
                }),
                pdfReport: new Field({
                    name: 'RELATORIO_EM_PDF',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPhotographicActivityReport extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioFotograficoAtividades',
            type: 'SEARCH',
            node: 'PONTOS_ATENDIDOS.PONTO_ATENDIDO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_START'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endingDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('PERIOD_END'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                initialTime: new Field({
                    name: 'HORA_INICIO',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('START_HOUR'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                conclusionTime: new Field({
                    name: 'HORA_CONCLUSAO',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('END_HOUR'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $select.issueType,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {
                            HABILITADO_CALLCENTER: -1,
                        },
                    },
                }),
                maintenanceNumbers: new Field({
                    name: 'IDS_ATENDIMENTO_PS',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',') : ''),
                    props: {
                        label: i18n.t('MAINTENANCE_NUM'),
                        _hint: null,
                    },
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $select.city,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    SOMENTE_ATIVOS: 1,
                                };
                            },
                        }],
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('AREA'),
                        entity: $select.area,
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.areaId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                withoutDistrict: new Field({
                    name: 'SEM_BAIRRO',
                    component: 'ACheckbox',
                    props: {
                        label: i18n.t('WITHOUT_DISTRICT'),
                        falseValue: 0,
                        trueValue: 1,
                    },
                    value: 0,
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_MUNICIPIO'],
                        disabled: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = fields.withoutDistrict.value ? true : !fields.worksiteId.value;
                            },
                        },
                        {
                            fieldName: 'ID_MUNICIPIO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = fields.withoutDistrict.value ? true : !fields.cityId.value;
                                fields.districtId.props['entity-params'] = {
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'SEM_BAIRRO',
                            handler: (fields) => {
                                if (fields.cityId.value) {
                                    fields.districtId.props.disabled = !!fields.withoutDistrict.value;
                                }
                            },
                        },
                    ],
                }),
                publicPlace: new Field({
                    name: 'LOGRADOURO',
                    component: 'ACombobox',
                    parser: (value) => (value ? (value.NOME_LOGRADOURO_COMPLETO ?? value) : null),
                    props: {
                        label: i18n.t('PUBLIC_PLACE'),
                        entity: $autocomplete.publicPlace,
                        clearable: true,
                        'reload-provider': true,
                        'item-text': (item) => (item ? item.NOME_LOGRADOURO_COMPLETO : null),
                        '_disabled-clear-not-found': true,
                        _minToSearch: 2,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_BAIRRO',
                            handler: function () {
                                this.fields.publicPlace.props['entity-params'] = { ID_BAIRRO: this.fields.districtId.value };
                            },
                        },
                    ],
                }),
                publicPlaceId: new Field({
                    name: 'ID_LOGRADOURO',
                    dependsOn: [
                        {
                            fieldName: 'LOGRADOURO',
                            handler: function () {
                                this.fields.publicPlaceId.value = this.fields.publicPlace.value?.ID_LOGRADOURO ?? null;
                            },
                        },
                    ],
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ORIGIN'),
                        entity: $select.issueOriginType,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $select.maintenanceStatus,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    SOMENTE_ATIVOS: 1,
                                };
                            },
                        }],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $select.maintenanceReasons,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO', 'ID_STATUS_ATENDIMENTO_PS'],
                    },
                    dependsOn: [{
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_STATUS_ATENDIMENTO_PS'],
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_STATUS_ATENDIMENTO_PS: fields.maintenanceStatusId.value,
                                };
                            },
                        }],
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $select.maintenanceSolutions,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO', 'ID_MOTIVO_ATENDIMENTO_PS'],
                    },
                    dependsOn: [{
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MOTIVO_ATENDIMENTO_PS'],
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MOTIVO_ATENDIMENTO_PS: fields.maintenanceReasonId.value,
                                };
                            },
                        }],
                }),
                groupStatusId: new Field({
                    name: 'ID_GRUPO_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('GROUP_STEPS'),
                        entity: $autocomplete.groupStatus,
                        'entity-params': { ID_GRUPO_STATUS_ENTIDADE: 1 },
                        clearable: true,
                    },
                }),
                statusId: new Field({
                    name: 'ID_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('STEP_OF_GROUP'),
                        entity: $autocomplete.statusGroup,
                        'entity-params': {},
                        'require-params': ['ID_GRUPO_STATUS'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_GRUPO_STATUS',
                            handler: (fields) => {
                                fields.statusId.props['entity-params'] = {
                                    ID_GRUPO_STATUS: fields.groupStatusId.value,
                                };
                                if (fields.groupStatusId.value == null) {
                                    fields.statusId.value = null;
                                }
                                fields.statusId.props.disabled = (fields.groupStatusId.value == null);
                            },
                        }],
                }),
                locationId: new Field({
                    name: 'ID_LOCALIZACAO',
                }),
                assetTypeId: new Field({
                    name: 'ID_TIPO_PONTO_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ASSET_TYPE'),
                        entity: $select.assetTypes,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetTypeId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    HABILITADO_CALLCENTER: null,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.teamSimplify,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                projectId: new Field({
                    name: 'ID_OBRA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('PROJECT'),
                        entity: $autocomplete.project,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.projectId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }],
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                    component: () => import('@components/multipleAutocomplete/MultipleAutocomplete.vue'),
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_ATRIBUTO);
                            return mapped.length > 0 ? mapped.join(',') : null;
                        }
                        return null;
                    },
                    props: {
                        _entity: $autocomplete.attribute,
                        '_entity-params': {},
                        _label: i18n.t('ATTRIBUTES'),
                        _deletableChips: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_TIPO_PONTO_SERVICO',
                            handler: (fields) => {
                                fields.attributeFilter.props['entity-params'] = {
                                    ID_TIPO_PONTO_SERVICO: fields.assetTypeId.value,
                                };
                            },
                        },
                    ],
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                    },
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GeneratePhotographicActivitiesReportPdf extends CallCommand {
    constructor() {
        super({
            name: 'GerarRelatorioFotograficoAtividadesPdf',
            type: 'NONE',
            fields: {
                situation: new Field({
                    name: 'SITUATION',
                }),
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endingDate: new Field({
                    name: 'DATA_CONCLUSAO',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                maintenances: new Field({
                    name: 'ATENDIMENTOS',
                }),
                maintenanceAttributes: new Field({
                    name: 'ATRIBUTOS_ATENDIMENTO',
                }),
                assetAttributes: new Field({
                    name: 'ATRIBUTOS_PONTO',
                }),
                issueAttributes: new Field({
                    name: 'ATRIBUTOS_OCORRENCIA',
                }),
                order: new Field({
                    name: 'ORDER',
                }),
                subTemplate: new Field({
                    name: 'SUB_TEMPLATE',
                }),
                observation: new Field({
                    name: 'OBSERVACAO',
                }),
                showAttendanceObservation: new Field({
                    name: 'EXIBIR_OBSERVACAO_ATENDIMENTO',
                }),
                showAllPhotos: new Field({
                    name: 'INCLUIR_TODAS_FOTOS',
                }),
                showPhotosLatLong: new Field({
                    name: 'INCLUIR_LAT_LONG_FOTOS',
                }),
                showPhotosAddress: new Field({
                    name: 'INCLUIR_ENDERECO_FOTOS',
                }),
                showPhotosDateTime: new Field({
                    name: 'INCLUIR_DATA_HORA_FOTOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GeneratePhotographicActivitiesReport extends CallCommand {
    constructor() {
        super({
            name: 'GerarRelatorioFotograficoAtividades',
            type: 'NONE',
            fields: {
                situation: new Field({
                    name: 'SITUATION',
                    value: 'excel1',
                }),
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endingDate: new Field({
                    name: 'DATA_CONCLUSAO',
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                }),
                maintenances: new Field({
                    name: 'ATENDIMENTOS',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GeneratePhotographicActivitiesReportWithAttributes extends CallCommand {
    constructor() {
        super({
            name: 'GerarRelatorioFotograficoAtividadesComAtributos',
            type: 'NONE',
            fields: {
                situation: new Field({
                    name: 'SITUATION',
                    value: 'excel1',
                }),
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endingDate: new Field({
                    name: 'DATA_CONCLUSAO',
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                }),
                maintenances: new Field({
                    name: 'ATENDIMENTOS',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
                attributes: new Field({
                    name: 'ATRIBUTOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetIssuesByDeadline extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPrazosAtendimento',
            type: 'NONE',
            fields: {
                situation: new Field({
                    name: 'SITUATION',
                }),
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        clearable: false,
                        'clear-provider': true,
                        multiple: true,
                    },
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.regions,
                        clearable: true,
                        'clear-provider': true,
                        'require-params': ['ID_PARQUE_SERVICO'],
                        'entity-params': {},
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                withoutRegionId: new Field({
                    name: 'ID_SEM_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WITHOUT_REGION'),
                        entity: $select.yesNoAll,
                        clearable: false,
                    },
                    value: -1,
                    dependsOn: [{
                            fieldName: ['ID_BAIRRO'],
                            handler: (fields) => {
                                fields.withoutRegionId.props.disabled = !fields.districtId.value;
                                if (fields.districtId.value) {
                                    fields.withoutRegionId.value = -1;
                                }
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.withoutRegionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: ['IDS_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_REGIAO: fields.worksiteId.region,
                                };
                                fields.districtId.props.disabled = !fields.worksiteId.value;
                                if (!fields.worksiteId.value) {
                                    fields.districtId.value = null;
                                }
                            },
                        }],
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _filter: false,
                    },
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                periodType: new Field({
                    name: 'TIPO_PERIODO',
                    component: () => import('@components/maintenancesRegardingDeadlineRadioGroup/MaintenancesRegardingDeadlineRadioGroup.vue'),
                    value: 0,
                    props: {},
                }),
                initialDate: new Field({
                    name: 'DATA_INICIAL_FILTRO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: false,
                        'enable-relative-picker': true,
                    },
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (this.fields.periodType.value) {
                                    this.fields.initialDate.value = null;
                                }
                                else {
                                    this.fields.initialDate.value = moment().subtract(6, 'months').format('DD/MM/YYYY');
                                }
                                this.fields.initialDate.construct = !this.fields.periodType.value;
                            },
                        }],
                }),
                finalDate: new Field({
                    name: 'DATA_FINAL_FILTRO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('END_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (this.fields.periodType.value) {
                                    this.fields.finalDate.value = null;
                                }
                                else {
                                    this.fields.finalDate.value = moment().format('DD/MM/YYYY');
                                }
                                this.fields.finalDate.construct = !this.fields.periodType.value;
                            },
                        }],
                }),
                initialMonth: new Field({
                    name: 'DATA_MES_INICIAL',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STARTING_MONTH'),
                        entity: $select.months,
                        clearable: false,
                    },
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (!this.fields.periodType.value) {
                                    this.fields.initialMonth.value = null;
                                }
                                this.fields.initialMonth.construct = !!this.fields.periodType.value;
                            },
                        }],
                    value: 1,
                }),
                initialYear: new Field({
                    name: 'DATA_ANO_INICIAL',
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (!this.fields.periodType.value) {
                                    this.fields.initialYear.value = null;
                                }
                                this.fields.initialYear.construct = !!this.fields.periodType.value;
                            },
                        }],
                }),
                finalMonth: new Field({
                    name: 'DATA_MES_FINAL',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ENDING_MONTH'),
                        entity: $select.months,
                        clearable: false,
                    },
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (!this.fields.periodType.value) {
                                    this.fields.finalMonth.value = null;
                                }
                                this.fields.finalMonth.construct = !!this.fields.periodType.value;
                            },
                        }],
                    value: 1,
                }),
                finalYear: new Field({
                    name: 'DATA_ANO_FINAL',
                    dependsOn: [{
                            fieldName: 'TIPO_PERIODO',
                            handler: () => {
                                if (!this.fields.periodType.value) {
                                    this.fields.finalYear.value = null;
                                }
                                this.fields.finalYear.construct = !!this.fields.periodType.value;
                            },
                        }],
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.teamSimplify,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }],
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                groupingLocationId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $select.groupingLocation,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                issuesType: new Field({
                    name: 'TIPOS_OCORRENCIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        multiple: true,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': { HABILITADO_PARQUE: null, SOMENTE_NAO_PREVENTIVAS: null, HABILITADO_CALLCENTER: null },
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issuesType.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                priorityId: new Field({
                    name: 'ID_PRIORIDADE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('PRIORITY'),
                        clearable: true,
                        entity: $select.issueAssetPriority,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        'clear-provider': true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.priorityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                                fields.priorityId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                detailed: new Field({
                    name: 'DETALHADO',
                    value: 0,
                }),
                confirmed: new Field({
                    name: 'CONFIRMADOS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONFIRMED'),
                        entity: $select.yesNoAll,
                        clearable: true,
                        'clear-provider': true,
                    },
                    value: 1,
                }),
                groupingType: new Field({
                    name: 'TIPO_AGRUPAMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('GROUPING_TYPE'),
                        entity: $select.maintenanceRegardingDeadlineGroupingType,
                    },
                    value: 1,
                }),
                complainOrigin: new Field({
                    name: 'ORIGEM_RECLAMACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('COMPLAIN_ORIGIN'),
                        entity: $autocomplete.issueTypeOrigin,
                        multiple: true,
                        clearable: true,
                        'clear-provider': true,
                        'entity-params': { SEM_RECLAMANTE: 0 },
                    },
                    dependsOn: [{
                            fieldName: ['TIPO_AGRUPAMENTO', 'IDS_PARQUE_SERVICO'],
                            handler: (fields) => {
                                if (!fields.worksiteId.value || fields.groupingType.value === 0) {
                                    fields.complainOrigin.props.disabled = true;
                                }
                                else {
                                    fields.complainOrigin.props.disabled = false;
                                }
                            },
                        }],
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetIssuesWithoutServiceOrder extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarOcorrenciasSemOS',
            type: 'SEARCH',
            node: 'OCORRENCIAS.OCORRENCIA',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                initialLocalNumber: new Field({
                    name: 'NUMERO_LOCAL_INICIAL',
                }),
                locationId: new Field({
                    name: 'ID_LOCALIZACAO',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                assetIssueIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveFile extends CallCommand {
    constructor() {
        super({
            name: 'IncluirArquivo',
            type: 'SAVE',
            fields: {
                fileId: new Field({
                    name: 'ID_ARQUIVO',
                }),
                fileGroupId: new Field({
                    name: 'ID_CONJUNTO_ARQUIVOS',
                }),
                fileType: new Field({
                    name: 'TIPO_ARQUIVO',
                }),
                fileName: new Field({
                    name: 'NOME_ARQUIVO',
                }),
                fileDate: new Field({
                    name: 'DATA_ARQUIVO',
                }),
                fileDetails: new Field({
                    name: 'DETALHES_ARQUIVO',
                }),
                latitude: new Field({
                    name: 'LATITUDE',
                }),
                longitude: new Field({
                    name: 'LONGITUDE',
                }),
                datetimeTimestamp: new Field({
                    name: 'DATA_HORA_TIMESTAMP',
                }),
                copyPdfData: new Field({
                    name: 'COPIAR_DADOS_PDF',
                }),
                mark: new Field({
                    name: 'MARCO',
                }),
                filedata: new Field({
                    name: 'filedata',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveMassMaintenanceServiceOrder extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAtendimentoEmMassaOrdemServico',
            type: 'SAVE',
            fields: {
                serviceOrdersIds: new Field({
                    name: 'IDS_ORDENS_SERVICO',
                }),
                jsonBaseMaintenance: new Field({
                    name: 'JSON_ATENDIMENTO_BASE',
                }),
                jsonStockCenter: new Field({
                    name: 'JSON_CENTRO_ESTOQUE',
                }),
                jsonInterventionAssetKnown: new Field({
                    name: 'JSON_INTERVENCOES_PONTO_CONHECIDO',
                }),
                jsonInterventionUnknownAsset: new Field({
                    name: 'JSON_INTERVENCOES_PONTO_DESCONHECIDO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetIssuesStatusServiceOrders extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarStatusTarefasOS',
            type: 'SEARCH',
            node: 'ORDENS_SERVICO.ORDEM_SERVICO',
            fields: {
                serviceOrdersIds: new Field({
                    name: 'IDS_ORDENS_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAllMaintenanceAttributes extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTodosAtributosAtendimentos',
            type: 'NONE',
            fields: {
                maintenances: new Field({
                    name: 'IDS_ATENDIMENTOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceEditHistory extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoEdicaoAtendimento',
            type: 'SEARCH',
            node: 'HISTORICO_EDICAO_ATENDIMENTOS.HISTORICO_EDICAO_ATENDIMENTO',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceAverageConclusionTime extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTempoMedioConclusaoAtendimentos',
            type: 'SEARCH',
            node: 'TEMPOS_MEDIOS.TEMPO_MEDIO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                initDateTime: new Field({
                    name: ['DATA_INICIO', 'HORA_INICIO'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('PERIOD_FROM'),
                        'text-field': true,
                        'return-serve': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.initDateTime.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.dateTimeToString,
                }),
                endDateTime: new Field({
                    name: ['DATA_CONCLUSAO', 'HORA_CONCLUSAO'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('PERIOD_TO'),
                        'text-field': true,
                        'return-serve': true,
                        'default-time': '23:59',
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.endDateTime.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                    parser: $util.dateTimeToString,
                }),
                issueTypeOriginId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ORIGIN'),
                        entity: $autocomplete.issueTypeOrigin,
                        'entity-params': { SEM_RECLAMANTE: 0 },
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.teamSimplify,
                        'require-params': ['ID_PARQUE_SERVICO'],
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }],
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props['entity-params'] = {
                                    HABILITADO_PARQUE: undefined,
                                    HABILITADO_CALLCENTER: undefined,
                                    SOMENTE_ATRASADAS: undefined,
                                    SOMENTE_PREVENTIVAS: undefined,
                                    SOMENTE_NAO_PREVENTIVAS: undefined,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $autocomplete.assistanceStatus,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        clearable: true,
                        disabled: false,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_TIPO_OCORRENCIA', 'ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceStatusId.props['entity-params'] = {
                                    SOMENTE_ATIVOS: 1,
                                    ID_TIPO_OCORRENCIA: fields.issueTypeId.value,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceStatusId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceMotive,
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_STATUS_ATENDIMENTO_PS', 'ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_STATUS_ATENDIMENTO_PS: fields.maintenanceStatusId.value,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $autocomplete.assitanceSolution,
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                        multiple: true,
                    },
                    dependsOn: [{
                            fieldName: ['ID_STATUS_ATENDIMENTO_PS', 'ID_MOTIVO_ATENDIMENTO_PS', 'ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_STATUS_ATENDIMENTO_PS: fields.maintenanceStatusId.value,
                                    ID_MOTIVO_ATENDIMENTO_PS: fields.maintenanceReasonId.value,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        }, {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                queryType: new Field({
                    name: 'TIPO_CONSULTA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('QUERY_TYPE'),
                        entity: $select.maintenanceQueryType,
                        clearable: false,
                    },
                    value: 1,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTotalManHoursByServiceOrderReport extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioTotalHorasHomemPorOS',
            type: 'SEARCH',
            node: 'HORAS_HOMEM_OS.HORAS_HOMEM_OS',
            fields: {
                initialDate: new Field({
                    name: 'DATA_INICIAL',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE_FILTRO',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA_FILTRO',
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_TIPO_OCORRENCIA);
                            return mapped.join(',');
                        }
                        return '';
                    },
                }),
                displayedAttributes: new Field({
                    name: 'ATRIBUTOS_EXIBIVEIS_RESULTADO',
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_ATRIBUTO);
                            return mapped.join(',');
                        }
                        return '';
                    },
                }),
                situation: new Field({
                    name: 'SITUATION',
                    value: '',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDetailedImproductivityReport extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioImprodutividades',
            type: 'SEARCH',
            fields: {
                initialDate: new Field({
                    name: 'DATA_INICIAL',
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                }),
                teamsIds: new Field({
                    name: 'ID_EQUIPES',
                }),
                workDiariesIds: new Field({
                    name: 'ID_DIARIOS_TRABALHO',
                }),
                worksitesIds: new Field({
                    name: 'ID_PARQUES_SERVICO',
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetStockCenterByTeam extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarEquipeCentroEstoque',
            type: 'SEARCH',
            node: 'CENTROS.CENTRO',
            fields: {
                teamId: new Field({
                    name: 'ID_EQUIPE',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                stockCenterTypeId: new Field({
                    name: 'ID_TIPO_CENTRO_ESTOQUE',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                isActive: new Field({
                    name: 'ATIVO',
                }),
                showTeam: new Field({
                    name: 'MOSTRAR_EQUIPE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ExportMaintenancesImages extends CallCommand {
    constructor() {
        super({
            name: 'ExportarImagensAtendimentos',
            type: 'NONE',
            fields: {
                maintenances: new Field({
                    name: 'ATENDIMENTOS',
                }),
                exportWithDate: new Field({
                    name: 'EXPORTAR_COM_DATA',
                }),
                exportWithLatLong: new Field({
                    name: 'EXPORTAR_COM_LAT_LONG',
                }),
                exportWithAddress: new Field({
                    name: 'EXPORTAR_COM_ENDERECO',
                }),
                situation: new Field({
                    name: 'SITUATION',
                    value: 'f1',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetActionEditHistory extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoEdicaoAcaoAtendimento',
            type: 'SEARCH',
            node: 'HISTORICO_EDICAO_ACAO_ATENDIMENTO.HISTORICO_EDICAO_ACAO_ATENDIMENTO',
            fields: {
                actionAfterMaintenanceId: new Field({
                    name: 'ID_ACAO_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class VerifyValidMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'VerificaAtendimentoValido',
            type: 'SEARCH',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetInvalidInterventionsItens extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarItensIntervencaoInvalidos',
            type: 'SEARCH',
            node: 'OPERACOES_MANUTENCAO_INVALIDAS.OPERCAO_INVALIDA',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UpdateMaintenanceInterventionVersion extends CallCommand {
    constructor() {
        super({
            name: 'AtualizaVersaoIntervencaoAtendimento',
            type: 'SAVE',
            fields: {
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                }),
                deleteInvalidOperations: new Field({
                    name: 'APAGAR_OPERACOES_MANUTENCAO_INVALIDAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ReverseMaintenance extends CallCommand {
    constructor() {
        super({
            name: 'EstornarAtendimentoPS',
            type: 'SAVE',
            fields: {
                jsonMaintenances: new Field({
                    name: 'JSON_ATENDIMENTO',
                }),
                preview: new Field({
                    name: 'PREVIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceOperationsAndServices extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRelatorioOperacoesManutencaoServicos',
            type: 'SEARCH',
            node: 'OPERACOES_MANUTENCAO_SERVICOS.OPERACAO_MANUTENCAO_SERVICO',
            fields: {
                worksiteIds: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                maintenanceId: new Field({
                    name: 'ID_ATENDIMENTO_PS',
                    component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
                    parser: (value) => (value != null && value.length !== 0 ? value.join(',') : ''),
                    props: {
                        label: i18n.t('MAINTENANCE_NUM'),
                        clearable: true,
                    },
                }),
                fromDateTime: new Field({
                    name: ['DATA_INICIO', 'HORA_INICIO'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('INITIAL_MAINTENANCE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                    },
                    parser: $util.dateTimeToString,
                }),
                toDateTime: new Field({
                    name: ['DATA_FIM', 'HORA_FIM'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('ENDING_MAINTENANCE_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'default-time': '23:59',
                        clearable: true,
                    },
                    parser: $util.dateTimeToString,
                }),
                warrantyType: new Field({
                    name: 'TIPO_GARANTIA',
                    component: 'ASelect',
                    value: 0,
                    props: {
                        label: i18n.t('WARRANTY_TYPE'),
                        clearable: false,
                        entity: $select.warrantyType,
                    },
                }),
                itemType: new Field({
                    name: 'TIPO_ITEM',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ITEM_TYPE'),
                        entity: $select.itemEntityType,
                    },
                    dependsOn: [
                        {
                            fieldName: 'TIPO_GARANTIA',
                            handler: (fields) => {
                                if (fields.warrantyType.value === 1) {
                                    fields.itemType.props.disabled = true;
                                    fields.itemType.value = null;
                                }
                                else {
                                    fields.itemType.props.disabled = false;
                                }
                            },
                        },
                    ],
                }),
                fromWarrantyDateTime: new Field({
                    name: ['DATA_INICIO_GARANTIA', 'HORA_INICIO_GARANTIA'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('INITIAL_WARRANTY_CONTRACTUAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                    },
                    parser: $util.dateTimeToString,
                    dependsOn: [
                        {
                            fieldName: 'TIPO_GARANTIA',
                            handler: (fields) => {
                                if (fields.warrantyType.value === 1) {
                                    fields.fromWarrantyDateTime.props.label = i18n.t('INITIAL_WARRANTY_MANUFACTURER_DATE');
                                }
                                else {
                                    fields.fromWarrantyDateTime.props.label = i18n.t('INITIAL_WARRANTY_CONTRACTUAL_DATE');
                                }
                            },
                        },
                    ],
                }),
                toWarrantyDateTime: new Field({
                    name: ['DATA_FIM_GARANTIA', 'HORA_FIM_GARANTIA'],
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('ENDING_WARRANTY_CONTRACTUAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'default-time': '23:59',
                        clearable: true,
                    },
                    parser: $util.dateTimeToString,
                    dependsOn: [
                        {
                            fieldName: 'TIPO_GARANTIA',
                            handler: (fields) => {
                                if (fields.warrantyType.value === 1) {
                                    fields.toWarrantyDateTime.props.label = i18n.t('ENDING_WARRANTY_MANUFACTURER_DATE');
                                }
                                else {
                                    fields.toWarrantyDateTime.props.label = i18n.t('ENDING_WARRANTY_CONTRACTUAL_DATE');
                                }
                            },
                        },
                    ],
                }),
                address: new Field({
                    name: 'ENDERECO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ADDRESS'),
                        clearable: true,
                    },
                }),
                plaque: new Field({
                    name: 'PLAQUETA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('PLAQUE'),
                        clearable: true,
                    },
                }),
                confirmed: new Field({
                    name: 'CONFIRMADO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONFIRMED'),
                        entity: $select.yesNo,
                    },
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('TEAM'),
                        entity: $select.team,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    TODAS: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                maintenanceOrigin: new Field({
                    name: 'ORIGEM_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_ORIGIN'),
                        entity: $select.originMaintenance,
                        clearable: true,
                    },
                }),
                missing: new Field({
                    name: 'DESAPARECIDO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MISSING_ITEM'),
                        entity: $select.yesNo,
                    },
                }),
                defective: new Field({
                    name: 'COM_DEFEITO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DEFECTIVE_WITH_ITEM'),
                        entity: $select.yesNo,
                    },
                }),
                itemCode: new Field({
                    name: 'COD_ITEM',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ITEM_CODE'),
                        clearable: true,
                    },
                }),
                quantity: new Field({
                    name: 'QUANTIDADE',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('QUANTITY'),
                        type: 'number',
                        clearable: true,
                    },
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $select.clientContracts,
                    },
                    dependsOn: [
                        {
                            fieldName: 'TIPO_GARANTIA',
                            handler: (fields) => {
                                if (fields.warrantyType.value === 1) {
                                    fields.contractId.props.disabled = true;
                                    fields.contractId.value = null;
                                }
                                else {
                                    fields.contractId.props.disabled = false;
                                }
                            },
                        },
                    ],
                }),
                underWarranty: new Field({
                    name: 'DENTRO_GARANTIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('IN_CONTRACT_WARRANTY'),
                        entity: $select.yesNo,
                    },
                    dependsOn: [
                        {
                            fieldName: 'TIPO_GARANTIA',
                            handler: (fields) => {
                                if (fields.warrantyType.value === 1) {
                                    fields.underWarranty.props.label = i18n.t('IN_MANUFACTURER_WARRANTY');
                                }
                                else {
                                    fields.underWarranty.props.label = i18n.t('IN_CONTRACT_WARRANTY');
                                }
                            },
                        },
                    ],
                }),
                groupStatusId: new Field({
                    name: 'ID_GRUPO_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('GROUP_STEPS'),
                        entity: $autocomplete.groupStatus,
                        'entity-params': { ID_GRUPO_STATUS_ENTIDADE: 1 },
                        clearable: true,
                    },
                }),
                statusId: new Field({
                    name: 'ID_STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('STEP_OF_GROUP'),
                        entity: $autocomplete.statusGroup,
                        'entity-params': {},
                        'require-params': ['ID_GRUPO_STATUS'],
                        clearable: true,
                        disabled: false,
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_GRUPO_STATUS',
                            handler: (fields) => {
                                fields.statusId.props['entity-params'] = {
                                    ID_GRUPO_STATUS: fields.groupStatusId.value,
                                };
                                if (fields.groupStatusId.value == null) {
                                    fields.statusId.value = null;
                                }
                                fields.statusId.props.disabled = (fields.groupStatusId.value == null);
                            },
                        }],
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                        _title: i18n.t('STEPS_ATTRIBUTES'),
                    },
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSuperModuleServiceOrder extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSuperModuloOrdemServico',
            type: 'NONE',
            fields: {
                serviceOrderId: new Field({
                    name: 'ID_ORDEM_SERVICO',
                }),
                serviceOrderIndex: new Field({
                    name: 'INDEX_ORDEM_SERVICO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteSatisfactionSurveyOriginType extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirTipoOrigemPesquisaSatisfacao',
            type: 'DELETE',
            fields: {
                satisfactionSurveyOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSatisfactionSurveyOriginType extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTipoOrigemPesquisaSatisfacao',
            type: 'SEARCH',
            node: 'TIPOS_ORIGEM_PESQUISA_SATISFACAO.TIPO_ORIGEM_PESQUISA_SATISFACAO',
            fields: {
                satisfactionSurveyOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveSatisfactionSurveyOriginType extends CallCommand {
    constructor() {
        super({
            name: 'SalvarTipoOrigemPesquisaSatisfacao',
            type: 'SAVE',
            fields: {
                satisfactionSurveyOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO',
                }),
                satisfactionSurveyOriginTypeDesc: new Field({
                    name: 'DESC_TIPO_ORIGEM_PESQUISA_SATISFACAO',
                }),
                responsibleRequired: new Field({
                    name: 'RESPONSAVEL_OBRIGATORIO',
                }),
                durationRequired: new Field({
                    name: 'DURACAO_OBRIGATORIA',
                }),
                enabledWeb: new Field({
                    name: 'HABILITADO_WEB',
                }),
                enabledIC: new Field({
                    name: 'HABILITADO_CI',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
